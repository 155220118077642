// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';


export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';


export const REQUEST_UPDATEPASSWORD = 'REQUEST_UPDATEPASSWORD';
export const SUCCESS_UPDATEPASSWORD = 'SUCCESS_UPDATEPASSWORD';

export const REQUEST_UPDATEUSER = 'REQUEST_UPDATEUSER';
export const SUCCESS_UPDATEUSER = 'SUCCESS_UPDATEUSER';
export const REQUEST_UPDATEUSERS = 'REQUEST_UPDATEUSERS';
export const SUCCESS_UPDATEUSERS = 'SUCCESS_UPDATEUSERS';

export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
export const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';

export const REQUEST_SAVE_USER = 'REQUEST_SAVE_USER';
export const SUCCESS_SAVE_USER = 'SUCCESS_SAVE_USER';

export const REQUEST_RESETPASSWORD = 'REQUEST_RESETPASSWORD';
export const SUCCESS_RESETPASSWORD = 'SUCCESS_RESETPASSWORD';




export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

export const ON_HIDE_MODAL = 'ON_HIDE_MODAL';
export const ON_SHOW_MODAL = 'ON_SHOW_MODAL';

export const REQUEST_GET_BOOKING = 'REQUEST_GET_BOOKING';
export const SUCCESS_GET_BOOKING = 'SUCCESS_GET_BOOKING';




export const REQUEST_GET_CUSTOMER = 'REQUEST_GET_CUSTOMER';
export const SUCCESS_GET_CUSTOMER = 'SUCCESS_GET_CUSTOMER';
export const REQUEST_SAVE_CUSTOMER = 'REQUEST_SAVE_CUSTOMER';
export const SUCCESS_SAVE_CUSTOMER = 'SUCCESS_SAVE_CUSTOMER';
export const REQUEST_UPDATE_CUSTOMER = 'REQUEST_UPDATE_CUSTOMER';
export const SUCCESS_UPDATE_CUSTOMER = 'SUCCESS_UPDATE_CUSTOMER';
export const REQUEST_DELETE_CUSTOMER = 'REQUEST_DELETE_CUSTOMER';
export const SUCCESS_DELETE_CUSTOMER = 'SUCCESS_DELETE_CUSTOMER';

export const REQUEST_GET_BRANCH = 'REQUEST_GET_BRANCH';
export const SUCCESS_GET_BRANCH = 'SUCCESS_GET_BRANCH';

export const REQUEST_SAVE_BRANCH = 'REQUEST_SAVE_BRANCH';
export const SUCCESS_SAVE_BRANCH = 'SUCCESS_SAVE_BRANCH';
export const REQUEST_UPDATE_BRANCH = 'REQUEST_UPDATE_BRANCH';
export const SUCCESS_UPDATE_BRANCH = 'SUCCESS_UPDATE_BRANCH';
export const REQUEST_DELETE_BRANCH = 'REQUEST_DELETE_BRANCH';
export const SUCCESS_DELETE_BRANCH = 'SUCCESS_DELETE_BRANCH';

export const REQUEST_GET_USERS = 'REQUEST_GET_USERS';
export const SUCCESS_GET_USERS = 'SUCCESS_GET_USERS';


export const REQUEST_GET_INDICATORTYPE = 'REQUEST_GET_INDICATORTYPE';
export const SUCCESS_GET_INDICATORTYPE = 'SUCCESS_GET_INDICATORTYPE';
export const REQUEST_SAVE_INDICATORTYPE = 'REQUEST_SAVE_INDICATORTYPE';
export const SUCCESS_SAVE_INDICATORTYPE = 'SUCCESS_SAVE_INDICATORTYPE';
export const REQUEST_UPDATE_INDICATORTYPE = 'REQUEST_UPDATE_INDICATORTYPE';
export const SUCCESS_UPDATE_INDICATORTYPE = 'SUCCESS_UPDATE_INDICATORTYPE';
export const REQUEST_DELETE_INDICATORTYPE = 'REQUEST_DELETE_INDICATORTYPE';
export const SUCCESS_DELETE_INDICATORTYPE = 'SUCCESS_DELETE_INDICATORTYPE';
 

export const REQUEST_GET_DESCRIPTION = 'REQUEST_GET_DESCRIPTION';
export const SUCCESS_GET_DESCRIPTION = 'SUCCESS_GET_DESCRIPTION';
export const REQUEST_SAVE_DESCRIPTION = 'REQUEST_SAVE_DESCRIPTION';
export const SUCCESS_SAVE_DESCRIPTION = 'SUCCESS_SAVE_DESCRIPTION';
export const REQUEST_UPDATE_DESCRIPTION = 'REQUEST_UPDATE_DESCRIPTION';
export const SUCCESS_UPDATE_DESCRIPTION = 'SUCCESS_UPDATE_DESCRIPTION';
export const REQUEST_DELETE_DESCRIPTION = 'REQUEST_DELETE_DESCRIPTION';
export const SUCCESS_DELETE_DESCRIPTION = 'SUCCESS_DELETE_DESCRIPTION';
 

export const REQUEST_GET_INDICATORMAPPING = 'REQUEST_GET_INDICATORMAPPING';
export const SUCCESS_GET_INDICATORMAPPING = 'SUCCESS_GET_INDICATORMAPPING';
export const REQUEST_SAVE_INDICATORMAPPING = 'REQUEST_SAVE_INDICATORMAPPING';
export const SUCCESS_SAVE_INDICATORMAPPING = 'SUCCESS_SAVE_INDICATORMAPPING';
export const REQUEST_UPDATE_INDICATORMAPPING = 'REQUEST_UPDATE_INDICATORMAPPING';
export const SUCCESS_UPDATE_INDICATORMAPPING = 'SUCCESS_UPDATE_INDICATORMAPPING';
export const REQUEST_DELETE_INDICATORMAPPING = 'REQUEST_DELETE_INDICATORMAPPING';
export const SUCCESS_DELETE_INDICATORMAPPING = 'SUCCESS_DELETE_INDICATORMAPPING';
 


export const REQUEST_GET_INDICATOR = 'REQUEST_GET_INDICATOR';
export const SUCCESS_GET_INDICATOR = 'SUCCESS_GET_INDICATOR';
export const REQUEST_SAVE_INDICATOR = 'REQUEST_SAVE_INDICATOR';
export const SUCCESS_SAVE_INDICATOR = 'SUCCESS_SAVE_INDICATOR';
export const REQUEST_UPDATE_INDICATOR = 'REQUEST_UPDATE_INDICATOR';
export const SUCCESS_UPDATE_INDICATOR = 'SUCCESS_UPDATE_INDICATOR';
export const REQUEST_DELETE_INDICATOR = 'REQUEST_DELETE_INDICATOR';
export const SUCCESS_DELETE_INDICATOR = 'SUCCESS_DELETE_INDICATOR';
 


export const REQUEST_GET_PROFILERSUMMARY = 'REQUEST_GET_PROFILERSUMMARY';
export const SUCCESS_GET_PROFILERSUMMARY = 'SUCCESS_GET_PROFILERSUMMARY';

export const REQUEST_GET_PROFILER = 'REQUEST_GET_PROFILER';
export const SUCCESS_GET_PROFILER = 'SUCCESS_GET_PROFILER';
export const REQUEST_GET_CUSTOMER_PROFILER = 'REQUEST_GET_CUSTOMER_PROFILER';
export const SUCCESS_GET_CUSTOMER_PROFILER = 'SUCCESS_GET_CUSTOMER_PROFILER';
export const REQUEST_SAVE_PROFILER = 'REQUEST_SAVE_PROFILER';
export const SUCCESS_SAVE_PROFILER = 'SUCCESS_SAVE_PROFILER';
export const REQUEST_UPDATE_PROFILER = 'REQUEST_UPDATE_PROFILER';
export const SUCCESS_UPDATE_PROFILER = 'SUCCESS_UPDATE_PROFILER';
export const REQUEST_DELETE_PROFILER = 'REQUEST_DELETE_PROFILER';
export const SUCCESS_DELETE_PROFILER = 'SUCCESS_DELETE_PROFILER';
 
export const REQUEST_GET_REVIEW = 'REQUEST_GET_REVIEW';
export const SUCCESS_GET_REVIEW = 'SUCCESS_GET_REVIEW';
export const REQUEST_SAVE_REVIEW = 'REQUEST_SAVE_REVIEW';
export const SUCCESS_SAVE_REVIEW = 'SUCCESS_SAVE_REVIEW';
export const REQUEST_UPDATE_REVIEW = 'REQUEST_UPDATE_REVIEW';
export const SUCCESS_UPDATE_REVIEW = 'SUCCESS_UPDATE_REVIEW';
export const REQUEST_DELETE_REVIEW = 'REQUEST_DELETE_REVIEW';
export const SUCCESS_DELETE_REVIEW = 'SUCCESS_DELETE_REVIEW';
 
export const REQUEST_GET_CUSTOMERREVIEW = 'REQUEST_GET_CUSTOMERREVIEW';
export const SUCCESS_GET_CUSTOMERREVIEW = 'SUCCESS_GET_CUSTOMERREVIEW';
export const REQUEST_SAVE_CUSTOMERREVIEW = 'REQUEST_SAVE_CUSTOMERREVIEW';
export const SUCCESS_SAVE_CUSTOMERREVIEW = 'SUCCESS_SAVE_CUSTOMERREVIEW';
export const REQUEST_UPDATE_CUSTOMERREVIEW = 'REQUEST_UPDATE_CUSTOMERREVIEW';
export const SUCCESS_UPDATE_CUSTOMERREVIEW = 'SUCCESS_UPDATE_CUSTOMERREVIEW';
export const REQUEST_DELETE_CUSTOMERREVIEW = 'REQUEST_DELETE_CUSTOMERREVIEW';
export const SUCCESS_DELETE_CUSTOMERREVIEW = 'SUCCESS_DELETE_CUSTOMERREVIEW';
 


export const REQUEST_GET_STATUS = 'REQUEST_GET_STATUS';
export const SUCCESS_GET_STATUS = 'SUCCESS_GET_STATUS';
export const REQUEST_SAVE_STATUS = 'REQUEST_SAVE_STATUS';
export const SUCCESS_SAVE_STATUS = 'SUCCESS_SAVE_STATUS';
export const REQUEST_UPDATE_STATUS = 'REQUEST_UPDATE_STATUS';
export const SUCCESS_UPDATE_STATUS = 'SUCCESS_UPDATE_STATUS';
export const REQUEST_DELETE_STATUS = 'REQUEST_DELETE_STATUS';
export const SUCCESS_DELETE_STATUS = 'SUCCESS_DELETE_STATUS';


export const REQUEST_GET_PERMISSION = 'REQUEST_GET_PERMISSION';
export const SUCCESS_GET_PERMISSION = 'SUCCESS_GET_PERMISSION';
export const REQUEST_SAVE_PERMISSION = 'REQUEST_SAVE_PERMISSION';
export const SUCCESS_SAVE_PERMISSION = 'SUCCESS_SAVE_PERMISSION';
export const REQUEST_UPDATE_PERMISSION = 'REQUEST_UPDATE_PERMISSION';
export const SUCCESS_UPDATE_PERMISSION = 'SUCCESS_UPDATE_PERMISSION';
export const REQUEST_DELETE_PERMISSION = 'REQUEST_DELETE_PERMISSION';
export const SUCCESS_DELETE_PERMISSION = 'SUCCESS_DELETE_PERMISSION';

export const REQUEST_GET_ROLE = 'REQUEST_GET_ROLE';
export const SUCCESS_GET_ROLE = 'SUCCESS_GET_ROLE';
export const REQUEST_SAVE_ROLE = 'REQUEST_SAVE_ROLE';
export const SUCCESS_SAVE_ROLE = 'SUCCESS_SAVE_ROLE';
export const REQUEST_UPDATE_ROLE = 'REQUEST_UPDATE_ROLE';
export const SUCCESS_UPDATE_ROLE = 'SUCCESS_UPDATE_ROLE';
export const REQUEST_DELETE_ROLE = 'REQUEST_DELETE_ROLE';
export const SUCCESS_DELETE_ROLE = 'SUCCESS_DELETE_ROLE';
 